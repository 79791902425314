/** Generated by FG **/
@font-face {
	font-family: 'Lato-Bold';
	src: url('../fonts/Lato-Bold.eot');
	src: local('☺'), url('../fonts/Lato-Bold.woff') format('woff'), url('../fonts/Lato-Bold.ttf') format('truetype'), url('../fonts/Lato-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: 'Lato-Regular';
	src: url('../fonts/Lato-Regular.eot');
	src: local('☺'), url('../fonts/Lato-Regular.woff') format('woff'), url('../fonts/Lato-Regular.ttf') format('truetype'), url('../fonts/Lato-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: 'Oswald-ExtraLight';
	src: url('../fonts/Oswald-ExtraLight.eot');
	src: local('☺'), url('../fonts/Oswald-ExtraLight.woff') format('woff'), url('../fonts/Oswald-ExtraLight.ttf') format('truetype'), url('../fonts/Oswald-ExtraLight.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: 'Oswald-Light';
	src: url('../fonts/Oswald-Light.eot');
	src: local('☺'), url('../fonts/Oswald-Light.woff') format('woff'), url('../fonts/Oswald-Light.ttf') format('truetype'), url('../fonts/Oswald-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: 'Oswald-Regular';
	src: url('../fonts/Oswald-Regular.eot');
	src: local('☺'), url('../fonts/Oswald-Regular.woff') format('woff'), url('../fonts/Oswald-Regular.ttf') format('truetype'), url('../fonts/Oswald-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: 'Oswald-Medium';
	src: url('../fonts/Oswald-Medium.eot');
	src: local('☺'), url('../fonts/Oswald-Medium.woff') format('woff'), url('../fonts/Oswald-Medium.ttf') format('truetype'), url('../fonts/Oswald-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: 'Oswald-SemiBold';
	src: url('../fonts/Oswald-SemiBold.eot');
	src: local('☺'), url('../fonts/Oswald-SemiBold.woff') format('woff'), url('../fonts/Oswald-SemiBold.ttf') format('truetype'), url('../fonts/Oswald-SemiBold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: 'Oswald-Bold';
	src: url('../fonts/Oswald-Bold.eot');
	src: local('☺'), url('../fonts/Oswald-Bold.woff') format('woff'), url('../fonts/Oswald-Bold.ttf') format('truetype'), url('../fonts/Oswald-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

